var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "public-header--wrapper" }, [
      _c(
        "div",
        { staticClass: "navigation container" },
        [
          _c(
            "el-row",
            {
              staticClass: "toolbar flex-w",
              attrs: {
                type: "flex",
                gutter: 20,
                justify: "space-between",
                align: "middle",
              },
            },
            [
              _c(
                "el-col",
                { staticClass: "logo", attrs: { span: 14, xs: 24 } },
                [_vm._v(" Port "), _c("span", [_vm._v("Tranzit")])]
              ),
              _c(
                "el-col",
                {
                  staticClass: "select-container",
                  attrs: { span: 10, xs: 24 },
                },
                [
                  _c("div", { staticClass: "select-label" }, [
                    _vm._v("Укажите терминал:"),
                  ]),
                  _c(
                    "el-select",
                    {
                      staticClass: "custom-component w-100p max-w-t-200p",
                      attrs: { size: "large", placeholder: "Выберите город" },
                      model: {
                        value: _vm.currentTerminal,
                        callback: function ($$v) {
                          _vm.currentTerminal = $$v
                        },
                        expression: "currentTerminal",
                      },
                    },
                    _vm._l(_vm.options, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _vm.unloadId
      ? _c(
          "div",
          { staticClass: "register-of-payments container" },
          [
            _c("div", { staticClass: "tableHeader" }, [
              _vm._v(" " + _vm._s(_vm.name) + " "),
            ]),
            _c(
              "vue-good-table",
              {
                attrs: {
                  "sort-options": { enabled: false },
                  "is-loading": _vm.loadingTable,
                  columns: _vm.columns,
                  rows: _vm.rows,
                  "max-height": "80vh",
                  "fixed-header": true,
                  "group-options": { enabled: true },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "table-header-row",
                      fn: function (props) {
                        return [
                          _c("span", { staticClass: "my-fancy-class" }, [
                            _vm._v(" " + _vm._s(props.row.label) + " "),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1787304175
                ),
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "emty-table",
                    attrs: { slot: "emptystate" },
                    slot: "emptystate",
                  },
                  [_vm._v("Данные отсутвуют")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "emty-table",
                    attrs: { slot: "loadingContent" },
                    slot: "loadingContent",
                  },
                  [_vm._v(" Ожидайте, данные загружаються... ")]
                ),
              ]
            ),
          ],
          1
        )
      : _vm._e(),
    !_vm.unloadId
      ? _c(
          "div",
          { staticClass: "register-of-payments container" },
          [
            _c("el-empty", {
              staticClass: "emty-table",
              attrs: {
                description: "Для получения статистики выберите терминал",
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }