<template>
  <div>
    <div class="public-header--wrapper">
      <div class="navigation container">
        <el-row
          type="flex"
          :gutter="20"
          justify="space-between"
          align="middle"
          class="toolbar flex-w"
        >
          <el-col :span="14" :xs="24" class="logo">
            Port <span>Tranzit</span>
          </el-col>
          <el-col :span="10" :xs="24" class="select-container">
            <div class="select-label">Укажите терминал:</div>
            <el-select
              v-model="currentTerminal"
              size="large"
              class="custom-component w-100p max-w-t-200p"
              placeholder="Выберите город"
            >
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-col>
        </el-row>
      </div>
    </div>
    <div v-if="unloadId" class="register-of-payments container">
      <div class="tableHeader">
        {{ name }}
      </div>
      <vue-good-table
        :sort-options="{ enabled: false }"
        :is-loading="loadingTable"
        :columns="columns"
        :rows="rows"
        max-height="80vh"
        :fixed-header="true"
        :group-options="{ enabled: true }"
      >
        <div slot="emptystate" class="emty-table">Данные отсутвуют</div>

        <div slot="loadingContent" class="emty-table">
          Ожидайте, данные загружаються...
        </div>
        <template slot="table-header-row" slot-scope="props">
          <span class="my-fancy-class">
            {{ props.row.label }}
          </span>
        </template>
      </vue-good-table>
    </div>
    <div v-if="!unloadId" class="register-of-payments container">
      <el-empty
        class="emty-table"
        description="Для получения статистики выберите терминал"
      />
    </div>
  </div>
</template>

<script>
import 'vue-good-table/dist/vue-good-table.css'
import {
  FETCH_TABLE_STATIC_DATA,
  FETCH_UNLOADS_LIST,
  GET_LOADINGS_TABLE_STATIC_DATA,
  GET_TABLE_STATIC_DATA,
  GET_UNLOADS_LIST,
  GET_UNLOAD_ID,
  SET_UNLOAD_ID,
} from '@/store/actions'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'RegisterOfPayments',
  metaInfo: {
    meta: [
      {
        vmid: 'adaptivity',
        name: 'viewport',
        content: 'width=1600, initial-scale=1',
      },
    ],
  },
  components: {
    VueGoodTable,
  },
  data() {
    return {
      columns: [
        {
          label: '',
          field: 'time',
          tdClass: 'text-left',
        },
        {
          label: 'Выдано тайм слотов',
          field: 'passTimeslot',
          type: 'number',
          tdClass: 'text-left',
          thClass: 'text-head-left',
        },
        {
          label: 'Пропускная способность в час',
          tdClass: this.rowStyleClassFn,

          field: 'issuedTimeslot',
          thClass: 'text-head-left',
        },
      ],
      rows: [],
    }
  },
  computed: {
    ...mapGetters({
      options: GET_UNLOADS_LIST,
      terminalData: GET_TABLE_STATIC_DATA,
      unloadId: GET_UNLOAD_ID,
      loadingTable: GET_LOADINGS_TABLE_STATIC_DATA,
    }),
    name() {
      return this.unloadId.name
    },
    currentTerminal: {
      get() {
        if (this.unloadId) {
          return this.unloadId.id
        }

        return null
      },
      set(newVal) {
        this.getTableData(newVal)
        this.setTerminal(this.options.find(t => t.id === newVal))
      },
    },
  },
  watch: {
    terminalData: {
      immediate: true,
      handler(value) {
        this.rows = []

        if (value.length > 0) {
          this.rows = value.map(item => ({
            mode: 'span', // span means this header will span all columns
            label: item.date, // this is the label that'll be used for the header
            html: false, // if this is true, label will be rendered as html
            children: item.children,
          }))
        }
      },
    },
  },
  mounted() {
    this.getUnloadsList()

    if (this.unloadId) {
      this.getTableData(this.unloadId.id)
    }
  },

  methods: {
    ...mapMutations({ setTerminal: SET_UNLOAD_ID }),
    ...mapActions({
      getUnloadsList: FETCH_UNLOADS_LIST,
      getTableData: FETCH_TABLE_STATIC_DATA,
    }),
    rowStyleClassFn(row) {
      if (row.issuedTimeslot > this.unloadId['trucks_per_hour_default']) {
        return 'green'
      }
      if (row.issuedTimeslot < this.unloadId['trucks_per_hour_default']) {
        return 'red'
      }

      return ''
    },
  },
}
</script>

<style lang="sass">

.green
  //color: #45D745!important
  ::after
    padding-left: 16px
    content: url('../../assets/icons/arrow-up-green.svg')
.red
  //color: red!important
  ::after
    padding-left: 16px
    content:  url('../../assets/icons/arrow-down-red.svg')
.my-fancy-class
  color: $color-blue-light
  font-weight: bold
  font-size: 18px
.text-head-left
  text-align: left
  color: $fontColor
  text-transform: uppercase
  font-size: 14px
.text-left
  text-align: left
.emty-table
  text-align: center
  color: $color-blue-light
.public-header--wrapper
  display: flex
  justify-content: space-between
  align-items: center
  background: $color-blue-light
  color: white
  font-size: 16px
  font-weight: bold
  .logo
    font-size: 25px
    line-height: 60px
    font-weight: 900
    text-transform: uppercase
    color: #fff
    & > span
      color: #333
  .navigation
    padding-bottom: 16px!important
    padding-top: 16px!important
.select-container
  display: flex
  justify-content: flex-start
  align-items: center
  background: $color-blue-light
  color: white
  font-size: 16px
  font-weight: bold
  el-select
    height: 40px!important
    width: 50%
  input
    height: 40px!important
  .select-label
    margin-right: 8px
    width: 50%!important

.tableHeader
  color: $color-blue-light
  text-align: left
  font-size: 16px
  padding: 0
  font-weight: bold
  text-transform: uppercase
  text-align: center
  padding: 18px
  border: 1px solid #EBEEF5
  background: #F5F7FA
  margin-bottom: -1px

.terminal-select
  display: flex
  align-items: center
.terminal-select__label
  color: $fontColor
  margin-right: 8px
  text-align: left
  font-size: 14px
.terminal-select__select
  min-width: 300px
.register-of-payments
  margin-top: 30px
  font-size: 14px
  color: #333
  .btn-group
    display: flex
    align-items: center
    justify-content: flex-end
    .btn
      margin-right: 25px
  .toolbar
    margin-bottom: 20px
    background-color: #F4F7FC
    color: $fontColor
  .fs-10
    color: $color-blue-light
  .registry-table.el-table
    border-bottom: 1px solid #EBEEF5 !important
    border-right: 1px solid #EBEEF5 !important
    .el-table__placeholder, .el-table__indent
      display: none
    td, th
      padding: 0
      border-top: 1px solid #EBEEF5 !important
      border-left: 1px solid #EBEEF5 !important
    .cell
      font-size: 14px
      line-height: 1
      color: $fontColor
      padding: 15px
      text-align: center
    td .cell
      white-space: nowrap
    .fs-10
      font-size: 14px
    .group-title .cell
      text-align: left
      font-weight: bold
      padding-left: 20px
      .el-table__header
        color: $fontColor !important
      .el-table__expand-icon
        display: none
</style>
